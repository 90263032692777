<template>
  <div class="home-page">
    
    <div class="homepage-top">
      <div class="homepage-text">
        <div class="title-and-tag">
          <h3 class="homepage-title">FIND JERSEY’S BEST</h3>
          <!-- <div class="tag one">
            <img style="width: 120px" src="https://njmonthly.com/wp-content/uploads/2014/11/headerNJM_script.png" />
          </div> -->
          <!-- <div class="tag two">
            <img style="width: 100px" src="https://njmonthly.com/wp-content/uploads/2014/11/headerNJM_serif.png" />
          </div> -->
          <div class="homepage-image mobile">
            <img src="https://njmonthly.com/wp-content/uploads/2014/11/byNJM.png" />
          </div>
        </div>
        <h4 class="homepage-subtitle">Your guide to all that's great in the Garden State</h4>
      </div>
      <div class="homepage-image desktop">
        <img src="https://njmonthly.com/wp-content/uploads/2014/11/byNJM.png" />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="simple-section col-md-6 col-xs-12">
            <div class="section-image">
              <router-link to="/search/restaurants">
                <img src="https://njmonthly.com/wp-content/uploads/2014/11/restaurant-seafood-dish-lq.jpg" />
              </router-link>
            </div>
            <div class="text">
              <div class="title">
                <router-link to="/search/restaurants">Restaurants</router-link>
              </div>
              <p>Let’s eat! This is our exclusive selection of the New Jersey’s best places to dine, curated by the editors of New Jersey Monthly, the state’s foremost dining experts. Search by region or genre to find Jersey’s Best.</p>
            </div>
        </div>
        <div class="simple-section col-md-6 col-xs-12">
            <div class="section-image">
              <router-link to="/search/spas">
                <img src="https://njmonthly.com/wp-content/uploads/2014/11/spa-image.jpg" />
              </router-link>
            </div>
            <div class="text">
              <div class="title">
                <router-link to="/search/spas">Spas</router-link>
              </div>
              <p>Relax! The New Jersey Monthly team of experts has chosen the best resort spas, day spas and medical spas in the state. Search by location to discover our selection of Jersey’s Best spas.</p>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'home',
}
</script>

<style lang="scss">

.homepage-top {
    text-align: center;
    max-width: none;
    background-image: url('https://njmonthly.com/wp-content/uploads/2014/11/map-north3.jpg');
    padding: 10px 30px;
    background-size: cover !important;
    background-position: center !important;
    margin-top: 4px;
    margin-bottom: 30px;
    color: white;
    padding-bottom: 55px;
}

.homepage-title {
  font-size: 43px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: black;
  margin: 30px 0px;
  line-height: 43px;
  display: inline-block;
  margin-bottom: 5px;
  font-family: "Sentinel A", "Sentinel B";
  font-style: normal;
  font-weight: 400;
}

.homepage-subtitle {
  text-transform: capitalize;
  color: black;
  font-size: 26px;
  margin-top: 4px;
  line-height: 26px;
  font-family: "Ideal Sans A", "Ideal Sans B";
  font-style: italic;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.homepage-image img {
  width: 57px;
}
.homepage-image {
  display: inline-block;
}
.homepage-text {
  display: inline-block;
}

.home-page.container .row {

    margin-bottom: 40px;

}



.homepage-image.mobile {
  display: block;
}
.homepage-image.desktop {
  display: none;
}
@media (min-width: 1015px) {

    .homepage-title {
        font-size: 80px;
        line-height: 70px;
    }
    .homepage-image img {
      width: 75px;
    }
}

@media (min-width: 768px) {
  .homepage-image.desktop {
    display: initial;
  }
  .homepage-image.mobile {
    display: none;
  }
}
</style>
